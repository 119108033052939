<template>
  <q-card>
    <div
        v-for="(collectionElement, i) in data"
        :key="collectionElement.id"
        :class="i < data.length - 1 ? 'border-bottom q-pa-sm' : 'q-pa-sm'"
        @click="handleClick(collectionElement)"
    >
      <div class="text-subtitle1 text-weight-bold text-center">
        {{ collectionElement.id }}<span v-if="collectionElement.extId">{{ + '/' + collectionElement.extId }}</span>
      </div >

      <div class="text-center">
        {{ rawData.entityClass }}
      </div>
    </div>
  </q-card>
</template>

<script>
export default {
  name: 'DynamicSequenceCollection',
  emits: ['click'],
  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    },
    rawData: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {}
  },
  computed: {
    title () {
      if (this.rawData.options) {
        return this.rawData.options.title
      }

      return this.rawData.title || ''
    }
  },
  methods: {
    handleClick (data) {
      const event = {
        type: this.rawData.entityClass,
        event: this.rawData.event,
        data
      }

      this.$emit('click', event)
    }
  }
}
</script>
